<template>
  
    <navbar :url="url"></navbar>
    <router-view></router-view>
    <my-footer></my-footer>

</template>

<script>

import Navbar from '../components/navbar/navbar.vue'
import myFooter from '../components/footer/myfooter.vue'

export default {
    name: 'Budaya',
    components: {
        Navbar,
        myFooter
    },
    data () {
        return {
            url: '/culture'
        }
    }
}
</script>

<style>

</style>